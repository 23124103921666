@charset "UTF-8"; /* CSS Document */
@import "bootstrap/dist/css/bootstrap.min.css";
@import "video.js/dist/video-js.min.css";

.videoPlayButtonNichtAnzeigen {
    display: none !important;
}

@media screen and (max-width:600px) {
    .home-wrapper{
        padding-bottom: 80px;
    }
}
// @media (-webkit-video-playable-inline) {
//     #either-gif-or-video img { display: none; }
//     #either-gif-or-video video { display: initial; }
// }